
import Vue from "vue";
import store from "@/store"

export default Vue.extend({
    name: "CreditScoreForm",

    props: {
        user: {
            type: Object,
            required: true
        },

    },

    data: () => ({
        valid: false,
        creditScoreRules: [
            (v: any) => !!v || "Credit score is required",
            (v: string | any) => /^\d{1,3}$/.test(v) || "Credit score should contain just digits and no more than 3 chars",
        ],
        creditScore: {
            "equifax": 0,
            "experian": 0,
            "transunion": 0,
        },
    }),

    async created() {
        await this.loadCreditScore()
    },

    methods: {
        // Load credit score for specific user
        async loadCreditScore(): Promise <void> {
            try{
                let res = await this.$API.user().getTheLatestCreditScore(this.user.id);
                if(res.success)
                {
                    delete res.data.updated_at;
                    this.creditScore = res.data;
                }

            }catch (e){
                await this.$store.dispatch('alert/showError', e.message);
            }
        },

        // Submit form data
        async submitCreditScoreForm(): Promise<void>{
            try {
                if (await (this.$refs.form as any).validate()){
                    let payload = {
                        ...this.creditScore
                    }
                    let res = await this.$API.user().updateCreditScore(this.user.id, payload);
                    this.$emit('submitted');
                    await this.$store.dispatch('alert/showSuccess', res.message);
                }
            }catch (e){
                await this.$store.dispatch('alert/showError', e.message)
            }
        }
    },
});
